@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(../assets/font.ttf) format('truetype');
}

* {
  font-family: 'Roboto', sans-serif;

  font-size: 14px;
  box-sizing: border-box;
}

.center-text {
  display: flex;
  align-self: center;
  margin-right: 8px;
}

.right-side {
  background: white;
}
.title {
  font-family: 'Lato', sans-serif;

  margin-top: -8px;
  margin-left: 11px;
}

body {
  background-color: #0f111a;
  display: flex;
  overflow: scroll;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  --primaryColor: #01579b;
}

footer {
  height: 10vh;
}

input[type='range'] {
  background-color: black;
}

.invert {
  background: black;
  color: white;
}
a,
a:visited {
  color: var(--primaryColor);
}

.app {
  background-color: white;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
}

h1 {
  color: var(--primaryColor);
  width: 100%;
  font-size: x-large;
  font-weight: normal;
  margin-bottom: 1.5rem;
}

h2 {
  color: var(--primaryColor);
  font-weight: normal;
}

.controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.controls button {
  background-color: var(--primaryColor);
  color: white;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-self: center;
  border: 0;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  height: 3rem;
}

.book-container {
  flex: 0.75;
}

label {
  display: flex;
  align-items: baseline;
  width: 100%;
  flex-wrap: wrap;
  min-height: 1.8rem;
  margin-bottom: 0.25rem;
}

label > span:first-child {
  min-width: 8rem;
  margin-bottom: 0.25rem;
  margin-top: 0.5rem;
}

label > span.inputs {
  flex: 1;
  display: flex;
  align-items: baseline;
  min-width: 15rem;
}

label input[type='color'] {
  align-self: center;
}

label input[type='range'] {
  flex: 1;
  align-self: center;
  color: red;
}

label input[type='number'] {
  flex: 0;
  min-width: 4rem;
  text-align: right;
  border: none !important;
  background: black;
  color: white;

  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity));
}

label input[type='text'] {
  flex: 1;
  border: none !important;
}

label input.color-input {
  max-width: 5rem;
  margin-right: 0.25rem;
}

label input[type='text'],
label input[type='number'] {
  border: 1px solid lightgray;
  padding: 0.25rem;
}

label input[type='text']:focus,
label input[type='number']:focus {
  outline: 0;
  border-color: var(--primaryColor);
}

label .unit {
  width: 2rem;
  margin-left: -10px;
}

.outputs {
  flex: 1;
  max-width: 100%;
}

.output style,
.output pre {
  border: 1px solid lightgray;
  display: block;
  white-space: pre;
  overflow-wrap: anywhere;
  font-family: Monaco, monospace;
  font-size: smaller;
  overflow: auto;
  max-height: 10rem;
}

input[type='range'] {
  height: 25px;
  -webkit-appearance: none;
  margin: 0 0;
  width: 100%;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: var(--primaryColor);
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type='range']::-webkit-slider-thumb {
  /* box-shadow: 0px 0px 0px #000000; */
  border: 1px solid var(--primaryColor);
  height: 20px;
  width: 10px;
  border-radius: 2px;
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
  background: var(--primaryColor);
  opacity: 1;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type='range']::-webkit-slider-thumb::hover {
  background: #1d2630;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: var(--primaryColor);
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: var(--primaryColor);
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid var(--primaryColor);
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: var(--primaryColor);
  opacity: 0.8;
  cursor: pointer;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: var(--primaryColor);
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type='range']::-ms-fill-upper {
  background: var(--primaryColor);
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type='range']::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid var(--primaryColor);
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: var(--primaryColor);
  opacity: 0.8;
  cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
  background: var(--primaryColor);
}
input[type='range']:focus::-ms-fill-upper {
  background: var(--primaryColor);
}

.self-promotion {
  background-color: #e3f2fd;
  padding: 0.75rem 1rem 1rem;
  width: 100%;
  text-align: center;
  border-radius: 1rem;
}

.range__slider {
  position: relative;
  width: 100%;
  height: calc(65px - 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  margin: 30px 0;
}
.range__slider::before,
.range__slider::after {
  position: absolute;
  color: #fff;
  font-size: 0.9rem;
  font-weight: bold;
}
.range__slider::before {
  content: attr(data-min);
  left: 10px;
}
.range__slider::after {
  content: attr(data-max);
  right: 10px;
}
.range__slider .length__title::after {
  content: attr(data-length);
  position: absolute;
  right: -16px;
  font-variant-numeric: tabular-nums;
  color: #fff;
}

#slider {
  -webkit-appearance: none;
  width: calc(100% - (70px));
  height: 2px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.314);
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
#slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}
#slider::-webkit-slider-thumb:hover {
  background: #d4d4d4;
  transform: scale(1.2);
}
#slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  transition: background 0.15s ease-in-out;
}
#slider::-moz-range-thumb:hover {
  background: #d4d4d4;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
/*
@keyframes spin3D {
  from {
    transform: rotate3d(0.5, 0.5, 0.5, 360deg);
  }
  to {
    transform: rotate3d(0deg);
  }
}

@keyframes configure-clockwise {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes configure-xclockwise {
  0% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(-135deg);
  }
  75% {
    transform: rotate(-225deg);
  }
  100% {
    transform: rotate(-315deg);
  }
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0.25;
    transform: scale(0.75);
  }
} */

.spinner-box {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

/* SPINNING CIRCLE */

.leo-border-1 {
  position: absolute;
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(63, 249, 220);
  background: linear-gradient(
    0deg,
    rgba(63, 249, 220, 0.1) 33%,
    rgba(63, 249, 220, 1) 100%
  );
  animation: spin3D 1.8s linear 0s infinite;
}

.leo-core-1 {
  width: 100%;
  height: 100%;
  background-color: #37474faa;
  border-radius: 50%;
}

.leo-border-2 {
  position: absolute;
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(251, 91, 83);
  background: linear-gradient(
    0deg,
    rgba(251, 91, 83, 0.1) 33%,
    rgba(251, 91, 83, 1) 100%
  );
  animation: spin3D 2.2s linear 0s infinite;
}

.leo-core-2 {
  width: 100%;
  height: 100%;
  background-color: #1d2630aa;
  border-radius: 50%;
}

/* ALTERNATING ORBITS */

.circle-border {
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(63, 249, 220);
  background: linear-gradient(
    0deg,
    rgba(63, 249, 220, 0.1) 33%,
    rgba(63, 249, 220, 1) 100%
  );
  animation: spin 0.8s linear 0s infinite;
}

.circle-core {
  width: 100%;
  height: 100%;
  background-color: #1d2630;
  border-radius: 50%;
}

/* X-ROTATING BOXES */

.configure-border-1 {
  width: 115px;
  height: 115px;
  padding: 3px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fb5b53;
  animation: configure-clockwise 3s ease-in-out 0s infinite alternate;
}

.configure-border-2 {
  width: 115px;
  height: 115px;
  padding: 3px;
  left: -115px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(63, 249, 220);
  transform: rotate(45deg);
  animation: configure-xclockwise 3s ease-in-out 0s infinite alternate;
}

.configure-core {
  width: 100%;
  height: 100%;
  background-color: #1d2630;
}

/* PULSE BUBBLES */

.pulse-container {
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pulse-bubble {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #3ff9dc;
}

.pulse-bubble-1 {
  animation: pulse 0.4s ease 0s infinite alternate;
}
.pulse-bubble-2 {
  animation: pulse 0.4s ease 0.2s infinite alternate;
}
.pulse-bubble-3 {
  animation: pulse 0.4s ease 0.4s infinite alternate;
}

/* SOLAR SYSTEM */

.solar-system {
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orbit {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fafbfc;
  border-radius: 50%;
}

.earth-orbit {
  width: 165px;
  height: 165px;
  -webkit-animation: spin 12s linear 0s infinite;
}

.venus-orbit {
  width: 120px;
  height: 120px;
  -webkit-animation: spin 7.4s linear 0s infinite;
}

.mercury-orbit {
  width: 90px;
  height: 90px;
  -webkit-animation: spin 3s linear 0s infinite;
}

.planet {
  position: absolute;
  top: -5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #3ff9dc;
}

.sun {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #ffab91;
}

.leo {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.blue-orbit {
  width: 165px;
  height: 165px;
  border: 1px solid #91daffa5;
  -webkit-animation: spin3D 3s linear 0.2s infinite;
}

.green-orbit {
  width: 120px;
  height: 120px;
  border: 1px solid #91ffbfa5;
  -webkit-animation: spin3D 2s linear 0s infinite;
}

.red-orbit {
  width: 90px;
  height: 90px;
  border: 1px solid #ffca91a5;
  -webkit-animation: spin3D 1s linear 0s infinite;
}

.white-orbit {
  width: 60px;
  height: 60px;
  border: 2px solid #ffffff;
  -webkit-animation: spin3D 10s linear 0s infinite;
}

.credit {
  align-self: center;
  margin-left: 10px;
}

.w1 {
  transform: rotate3D(1, 1, 1, 90deg);
}

.w2 {
  transform: rotate3D(1, 2, 0.5, 90deg);
}

.w3 {
  transform: rotate3D(0.5, 1, 2, 90deg);
}

.three-quarter-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #fb5b53;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 0.5s linear 0s infinite;
}

.h-75 {
  height: 75vh;
}
.footer {
  /* position: absolute;
  bottom: 0;
  margin: 0 auto;
  width: 100%; */
}

@media (min-width: 0px) and (max-width: 900px) {
  body {
    height: unset;
  }
  .content-box {
    flex-direction: column;
  }
  .book {
    height: 485px !important;
  }
}
/* @media screen and (max-width: 995px), screen and (max-height: 870px) {
  body {
    height: unset;
  }
} */

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #3c3c3c;
  cursor: pointer;
}
